var inputList = ['#formCorpusFormats', '#formAnnotationsFormats'];
var customPlaceholders = ['"ANNIS"','"ANNIS"'];

for(var h = 0; h < inputList.length; h++) {
  $(inputList[h]).flexdatalist({
    minLength: 0
  });
  let i = h
  $(inputList[i] + '-flexdatalist').attr('placeholder', customPlaceholders[i]);

  $(inputList[i]).on('change:flexdatalist', function(event, set, options) {
    if($(inputList[i]).flexdatalist('value').length > 0) {
      $(inputList[i] + '-flexdatalist').attr('placeholder', '');
    } else {
      $(inputList[i] + '-flexdatalist').attr('placeholder', customPlaceholders[i]);
    }
  });
}