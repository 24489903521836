// fileUpload library "DropzoneJS"
// Documentation: http://www.dropzonejs.com/

function toggleViewUpload() {
    // hide enter-upload button
    $('.enterLogoUpload').toggleClass('hidden');
    // show leave-upload button
    $('.leaveLogoUpload').toggleClass('hidden');
    // hide upload area
    $('.uploadArea').toggleClass('hidden');
    // show upload placeholder
    $('.uploadPlaceholder').toggleClass('hidden');
}

function toggleUploadStatus() {
    // display the preview and progress things
    $('.logoUpload-end').toggleClass('hidden');
    // hide the default view
    $('.logoUpload-start').toggleClass('hidden');
}

// prevent double dropzones
Dropzone.autoDiscover = false;

var previewNode = document.querySelector("#template");
if(previewNode) {

  // change view from cancel to upload
  $('.enterLogoUpload').click(() => {
    toggleViewUpload();
  });

  // change view from upload to cancel
  $('.leaveLogoUpload').click(() => {
    toggleViewUpload();
  });

  // see Documentation: http://www.dropzonejs.com/
  previewNode.id = "";
  var previewTemplate = previewNode.parentNode.innerHTML;
  previewNode.parentNode.removeChild(previewNode);

  // init dropzoneJS
  var logoUpload = new Dropzone(document.body, { // Make the whole body a dropzone
    url: "http://localhost:3500", // Set the url
    parallelUploads: 20,
    maxFiles: 1,
    previewTemplate: previewTemplate,
    autoQueue: true, // Make sure the files aren't queued until manually added
    previewsContainer: "#previews", // Define the container to display the previews
    clickable: '.uploadArea' // Define the element that should be used as click trigger to select files.
  });

  // DropzoneJS event methods
  logoUpload.on("addedfile", function(file) {
    toggleUploadStatus();
  });

  logoUpload.on("success", function(event, response) {
    // display thumbnail, hide progress bar, display other bbuttons, hide previous buttons
    $('.uploadThumbnail').toggleClass('hidden');
    $('.uploadProgress').toggleClass('hidden');
    $(".logoUpload-delete").click(function() {
      toggleUploadStatus();
      logoUpload.removeAllFiles(true);
    });
  });

  // trigger the file picker on btn click via hidden input field
  $('body').on('click', '.uploadButton', function () {
    logoUpload.hiddenFileInput.click();
    logoUpload.removeAllFiles();
    toggleUploadStatus();
  });
}