// After DOM is ready ...
$(document).ready(() => {

  // validate all text inputs in corresponding form, if they are empty. If not, activate submit button
  $('input.toBeValidated').blur(function(){
    let parentForm = $(this).closest('form')
    let submitBtn = $(parentForm).find('.toCheckValidation')

    let emptyFields = $(parentForm).find('input.toBeValidated').filter(function() {
      return $.trim(this.value) === "";
    });

    if(emptyFields.length > 0) {
      if(!$(submitBtn).hasClass('disabled')) {
        $(submitBtn).addClass('disabled')
      }
    } else {
      $(submitBtn).removeClass('disabled')
    }
  });


  // validate all checkbox inputs in corresponding form, if they are checked. If, activate submit button
  $('input.toBeValidated-checkbox').change(function(){
    let parentForm = $(this).closest('form')
    let submitBtn = $(parentForm).find('.toCheckValidation')

    let uncheckedBoxes = $(parentForm).find('input.toBeValidated-checkbox:not(:checked)')

    if(uncheckedBoxes.length > 0) {
      if(!$(submitBtn).hasClass('disabled')) {
        $(submitBtn).addClass('disabled')
      }
    } else {
      $(submitBtn).removeClass('disabled')
    }
  });


  // Button Validation for Admin - Publish - Tables -- Select/Delete All
  $('.documents-table input:checkbox').change(function(){
    if($('.documents-table tbody input:checkbox:checked').length > 0) {
        $('.documents-table tfoot button').removeClass('disabled');
    } else {
        $('.documents-table tfoot button').addClass('disabled');
    }
  });


  // Admin - Publish - Modal validate if all checks are passed
  // $('#publishCorpusModal ...ELEMENTS_TO_TEST_ON...').change(function() {
  //   // here goes your code for deciding whether to activate the "publish"-button or not

  //   // activate the "publish"-button
  //   $('#publishCorpusModal modal-footer button').removeClass('disabled');
  // });


  // Admin - Publish - Preview validation
  // Here you should define your own rules, whether to activate the "preview"-button
  // the element to change:
  // $('#corpusMainActions .corpusPreview').removeClass('disabled');

});