$(document).ready(() => {

  // initialize unslider-carousel for the slides on the home page
  $('.homeCarousel').unslider({
    infinite: true,
    arrows: {
      prev: '<a class="unslider-arrow prev"><i class="fa fa-2x fa-angle-left text-wine-trans"></i></a>',
      next: '<a class="unslider-arrow next"><i class="fa fa-2x fa-angle-right text-wine-trans"></i></a>',
    }
  });

  // after carousel initialization, match the height of the two boxes on home page (flexbox due to col-row-grid not usable)
  $(function() {
    $('.matchedHeight').matchHeight();
  });
});