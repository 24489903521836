$('#toTop').on("click", function(event) {
  event.preventDefault();
  console.log('jo')
  $("html, body").animate(
    {
      scrollTop: $('#rootContainer').offset().top
    },
    500
  );
});
