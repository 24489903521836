
// EDIT button in corpusProject Edits
$('.corpusProject .corpusProject-startEdit').click(function() {
  let corpusProject = $(this).closest('.corpusProject');
  corpusProject.find('.corpusProject-save').toggleClass('hidden');
  corpusProject.find('.corpusProject-edit').toggleClass('hidden');

  let title = corpusProject.find('.corpusProject-title').text().trim();
  let description = corpusProject.find('.corpusProject-description').text().trim();

  corpusProject.find('.corpusProject-description-edit').val(description);
  corpusProject.find('.corpusProject-title-edit').val(title);
});

// SAVE button in corpusProject Edits
$('.corpusProject .corpusProject-saveEdit').click(function() {
  let corpusProject = $(this).closest('.corpusProject');
  corpusProject.find('.corpusProject-save').toggleClass('hidden');
  corpusProject.find('.corpusProject-edit').toggleClass('hidden');

  // -- -- -- --
  // Here you can submit the changes to the form to the backend of whatever
  // -- -- -- --
});

// CANCEL button in corpusProject Edits
$('.corpusProject .corpusProject-endEdit').click(function() {
  let corpusProject = $(this).closest('.corpusProject');
  corpusProject.find('.corpusProject-save').toggleClass('hidden');
  corpusProject.find('.corpusProject-edit').toggleClass('hidden');
});