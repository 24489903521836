// fileUpload library "DropzoneJS"
// Documentation: http://www.dropzonejs.com/

// prevent double dropzones
Dropzone.autoDiscover = false;

var previewNode = document.querySelector("#corpusUploadTemplate");
if(previewNode) {

  // see documentation: http://www.dropzonejs.com/
  previewNode.id = "";
  var previewTemplate = previewNode.parentNode.innerHTML;
  previewNode.parentNode.removeChild(previewNode);

  // init dropzoneJS
  var corpusUpload = new Dropzone(document.body, { // Make the whole body a dropzone
    url: "http://localhost:3500", // Set the url
    parallelUploads: 20,
    uploadMultiple: true,
    previewTemplate: previewTemplate,
    autoQueue: true, // Make sure the files aren't queued until manually added
    previewsContainer: "#previews", // Define the container to display the previews
    clickable: '.uploadArea' // Define the element that should be used as click trigger to select files.
  });

  // DropzoneJS event methods
  corpusUpload.on("processing", function(file) {
    $(file.previewElement).find('.uploadStatusText').text('Uploading');
    $(file.previewElement).find('.uploadStatusIcons').find('.uploadCancel').removeClass('hidden');
  });

  corpusUpload.on("success", function(file) {
    $(file.previewElement).find('.uploadStatusText').text('Completed');
    $(file.previewElement).find('.uploadStatusIcons').children().addClass('hidden');
    $(file.previewElement).find('.uploadSuccessIcon').removeClass('hidden');
  });

  corpusUpload.on("complete", function() {
    // validate and activate the "finish upload"-button
    $('.uploadActions .btn').removeClass('disabled');
  });

  corpusUpload.on("error", function(file) {
    $(file.previewElement).find('.uploadStatusText').text('');
    $(file.previewElement).find('.uploadStatusIcons').children().addClass('hidden');
    $(file.previewElement).find('.uploadErrorIcon').removeClass('hidden');
  });

  corpusUpload.on("processingmultiple", function(elementList) {
    $('.uploadLength').removeClass('hidden');
    $('.uploadLength').text(elementList.length + ' File(s) queued.');
  });

  // init click event for cancel-func on body, because cancel button is not loaded initially due to preview-template-removal at start
  $('body').on('click', '.uploadCancel', function () {
    corpusUpload.removeAllFiles(true);
  });
}