const suggestions = [
  {
    "value": "Curry",
    "data": "Noreen"
  },
  {
    "value": "Sanders",
    "data": "Hogan"
  },
  {
    "value": "Cherry",
    "data": "Althea"
  },
  {
    "value": "Alyce",
    "data": "Adela"
  },
  {
    "value": "Davidson",
    "data": "Misty"
  },
  {
    "value": "Dorothea",
    "data": "Cornelia"
  },
  {
    "value": "Mathis",
    "data": "Stein"
  },
  {
    "value": "Marsh",
    "data": "Eve"
  },
  {
    "value": "Danielle",
    "data": "Mcfarland"
  },
  {
    "value": "Desiree",
    "data": "Pacheco"
  },
  {
    "value": "Knox",
    "data": "Guerrero"
  },
  {
    "value": "Myra",
    "data": "Berta"
  },
  {
    "value": "Kellie",
    "data": "Gretchen"
  },
  {
    "value": "Jeanette",
    "data": "Andrea"
  },
  {
    "value": "Workman",
    "data": "Ballard"
  },
  {
    "value": "Moran",
    "data": "Rebekah"
  },
  {
    "value": "Alana",
    "data": "Leanne"
  },
  {
    "value": "Suarez",
    "data": "Concetta"
  },
  {
    "value": "Felecia",
    "data": "Zamora"
  },
  {
    "value": "Flowers",
    "data": "Noel"
  },
  {
    "value": "Thornton",
    "data": "Colette"
  },
  {
    "value": "Susanna",
    "data": "Roy"
  },
  {
    "value": "Hatfield",
    "data": "Skinner"
  },
  {
    "value": "Blankenship",
    "data": "Fisher"
  },
  {
    "value": "Mitchell",
    "data": "Bryan"
  }
]

// Initialize autocompletion for every filter form input

$('#formCorpusTitle').autocomplete({
  lookup: suggestions
});
$('#formCorpusLanguage').autocomplete({
  lookup: suggestions
});
$('#formCorpusPublisher').autocomplete({
  lookup: suggestions
});
$('#formCorpusLicenses').autocomplete({
  lookup: suggestions
});


$('#formDocumentsTitle').autocomplete({
  lookup: suggestions
});
$('#formDocumentsAuthor').autocomplete({
  lookup: suggestions
});
$('#formDocumentsLanguage').autocomplete({
  lookup: suggestions
});
$('#formDocumentsPlace').autocomplete({
  lookup: suggestions
});


$('#formAnnotationsTitle').autocomplete({
  lookup: suggestions
});
$('#formAnnotationsLanguage').autocomplete({
  lookup: suggestions
});
